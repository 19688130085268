import PhoneNumber from '@/lib/stringSubset/PhoneNumber.ts';

export default class PhoneLine {
  /** Describes the context of this line's usage, i.e. mobile, home, work, etc. */
  label: string | null;

  /** The phone number associated with the phone line */
  number: PhoneNumber;

  constructor({ label, number }: { label: string | null, number: PhoneNumber }) {
    this.label  = label;
    this.number = number;
  }
}
