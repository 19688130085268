import Spinner from '@/components/Spinner';

export default {
  components: {
    Spinner,
  },
  methods: {
    t(path, replaceStr = '') {
      const text = this.$vuetify.lang.t('$vuetify.' + path);
      return text.replace('{{}}', replaceStr);
    },
  },
};
