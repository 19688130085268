import {
  Contacts as NativeContactPlugin,
  PhoneType,
  type ContactPayload,
} from '@capacitor-community/contacts';

import {
  compact,
  isEmpty,
  isNil,
  sortBy,
} from 'lodash';

import {
  castAsError,
} from '@/lib/utilitiesByType/error.ts';

import PhoneNumber, {
  asNullablePhoneNumber,
} from '@/lib/stringSubset/PhoneNumber.ts';

import PhoneLine from '@/models/PhoneLine.ts';

type DeviceContact = {
  id: string,
  first_name: string,
  last_name: string,
  mobile: string | null,
  photo: string, // TODO: rename to `photoAsBase64DataURL`
}

/** The phone number most likely to be a mobile number based on the associated label */
function firstMobileNumberAmongst(givenLines: PhoneLine[]): PhoneNumber | null {
  const useablePhoneLines = givenLines.filter(($0) => $0.label !== 'home');

  if (isEmpty(useablePhoneLines)) return null;

  const [topRankedPhoneLine] = sortBy(useablePhoneLines, (givenLine: PhoneLine): number => {
    const labelsSortedByHighestToLowestRank = ['iphone', 'mobile', 'phone', 'work', null];
    const indexOfPhoneLine = labelsSortedByHighestToLowestRank.indexOf(givenLine.label?.toLowerCase() ?? null);

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (indexOfPhoneLine === -1) return labelsSortedByHighestToLowestRank.length;

    return indexOfPhoneLine;
  });

  return topRankedPhoneLine?.number ?? null;
}

/** Used to interface with the device's contacts. Public members use in-project models */
export default class DeviceContactDirectory {
  private static constructDeviceContactFrom(givenContact: ContactPayload): DeviceContact | null {
    const { contactId, name, phones, image } = givenContact;

    const sparsePhoneLines = phones?.map(({ type, label, number: unsafeNumber }) => {
      const effectiveLabel = (type === PhoneType.Custom) ? label : type;
      const number = asNullablePhoneNumber(unsafeNumber);

      if (isNil(number)) return null;

      return new PhoneLine({ label: effectiveLabel ?? null, number });
    }) ?? [];

    const phoneLines = compact(sparsePhoneLines);

    if (isEmpty(phoneLines)) return null;

    if (isNil(name) || (isEmpty(name.given) && isEmpty(name.family))) return null;

    return {
      id: contactId,
      first_name: name.given ?? '',
      last_name: name.family ?? '',
      mobile: firstMobileNumberAmongst(phoneLines),
      photo: image?.base64String ?? '',
    };
  }

  public static async checkCalendarReadWritePermission(): Promise<boolean | null> {
    const permissionStatus = await NativeContactPlugin.checkPermissions();

    switch (permissionStatus.contacts) {
    case 'granted': return true;
    case 'denied' : return false;
    default       : return null;
    }
  }

  public static async requestCalendarReadWritePermission(): Promise<boolean> {
    const { contacts } = await NativeContactPlugin.requestPermissions();
    return contacts === 'granted';
  }

  public static async tryToReadAllContacts(): Promise<DeviceContact[] | null> {
    try {
      const { contacts } = await NativeContactPlugin.getContacts({ projection: {
        name: true,
        phones: true,
        image: true,
      } });

      const sparseContacts = contacts.map(($0) => this.constructDeviceContactFrom($0));
      return compact(sparseContacts);
    } catch (errorToBeCasted) {
      const error = castAsError(errorToBeCasted);

      if (error.message === 'Permission is required to access contacts.') return null;

      throw error;
    }
  }
}
