import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';

import App from '@/App.vue';
import router from '@/router/index.js';
import store from '@/store/index.js';
import pinia from '@/pinia.ts';
import vuetify from '@/vuetify.js';
import mixins from '@/mixins/index.js';
import '@/lib/trackjs.js';

Vue.use(Vuetify);
Vue.mixin(mixins);

new Vue({
  el: '#app',
  store,
  router,
  vuetify,
  pinia,
  template: '<App/>',
  components: { App },
});
