import {
  clone,
} from 'lodash';

import ConveYourEndpoint from '@/api/ConveYourEndpoint.js';

export default {
  state: {
    introductionInfo: {},
    introductionLoading: false,
  },

  mutations: {
    setIntroductionLoading: (state, loading) => {
      state.introductionLoading = loading;
    },
    setIntroductionInfo: (state, introductionInfo) => {
      state.introductionInfo = introductionInfo;
    },
  },

  getters: {
    introductionLoading(state) {
      return state.introductionLoading;
    },
    introductionContacts(state) {
      return state.introductionInfo.contacts;
    },
    introductionUser(state) {
      return state.introductionInfo.user;
    },
  },

  actions: {
    async loadIntroductionInfo({ commit }, pid) {
      commit('setIntroductionLoading', true);

      try {
        const introductionInfo = await ConveYourEndpoint.getIntroduction({ pid });

        introductionInfo.contacts = introductionInfo.contacts.reduce((contactsMissingMobile, contact) => {
          if (!contact.mobile) contactsMissingMobile.push(clone(contact));

          return contactsMissingMobile;
        }, []);

        commit('setIntroductionInfo', introductionInfo);
        commit('setRep', introductionInfo.rep);
      } finally {
        commit('setIntroductionLoading', false);
      }
    },

    async loadIntroductionRep({ commit }, pid) {
      commit('setIntroductionLoading', true);

      try {
        const res = await ConveYourEndpoint.getIntroduction({ pid });
        commit('setRep', res?.rep || null);
      } finally {
        commit('setIntroductionLoading', false);
      }
    },

    async loadIntroductionBookingSnippets(_, pid) {
      const res = await ConveYourEndpoint.getIntroductionBookingSnippets({ pid });
      return res?.message || [];
    },
  },
};
