import {
  cloneDeep,
} from 'lodash';

import ConveYourEndpoint from '@/api/ConveYourEndpoint.js';
import Segment from '@/api/Segment.ts';

export default {
  state: {
    profile: null,
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    profileId(state) {
      return state.profile?.id;
    },
  },
  actions: {
    async tryToSignInProspect({ commit, getters, dispatch }, { havingAppointmentWith: meetingCode, reachableAt: mobileNumber }) {
      const rep_number = localStorage.getItem('repNumber') || getters.repNumber;

      const {
        access_token,
        contact: fetchedProspect,
      } = await ConveYourEndpoint.getTokenAndProfile({ rep_number, pin: meetingCode, mobile: mobileNumber });

      if (access_token) {
        localStorage.setItem('user-token', access_token);
      }

      await dispatch('loadRep', fetchedProspect.id);

      Segment.identify(fetchedProspect, getters.rep);

      commit('setProfile', fetchedProspect);

      return cloneDeep(fetchedProspect);
    },
  },
  mutations: {
    setProfile: (state, profile) => {
      state.profile = profile;
    },
  },
};
