import {
  isFunction,
  isObject,
  isString,
} from 'lodash';

export function assert(didPassAssertion: boolean, errorMessage: string): void {
  if (!didPassAssertion) throw new TypeError(errorMessage);
}

export function assertIsInstanceOf<T>(GivenConstructor: { new (...params: never[]): T }): (givenValue: unknown) => void {
  assert(isFunction(GivenConstructor), 'Expected function');

  return (givenValue: unknown) => {
    assert(givenValue instanceof GivenConstructor, `Expected instance of ${GivenConstructor.name}`);
  };
}

export function assertUsing(
  matchesGivenPredicate: (givenValue: unknown) => boolean,
  errorMessage: string,
): (givenValue: unknown) => void {
  return (givenValue) => {
    assert(matchesGivenPredicate(givenValue), errorMessage);
  };
}

export const assertIsString = assertUsing(isString, 'Expected string');

export const assertIsObject = assertUsing(isObject, 'Expected object');

export function assertIsNullableString(givenSubject: unknown): givenSubject is string | null {
  if (givenSubject !== null) assertIsString(givenSubject);

  return true;
}
