import Vue from 'vue';
import Router from 'vue-router';
import { Capacitor } from '@capacitor/core';

Vue.use(Router);

import Layout from '@/components/Layout';
import landingRoutes from '@/router/landingRoutes';
import appRoutes from '@/router/appRoutes';

const routes = [
  {
    path: '/',
    component: Layout,
    children: appRoutes,
  },
];

if (!Capacitor.isNativePlatform()) {
  routes.push(...landingRoutes);
}

export default new Router({
  routes,
  mode: 'hash',
  scrollBehavior() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    return { x: 0, y: 0 }; // eslint-disable-line id-length
  },
});
