export default {
  background: '#fff',
  primary: '#0082C4',
  success: '#0082C4',
  secondary: '#000',
  inactive: '#757575',
  light: '#fff',
  dark: '#000',
  main: '#000',
  mainInvert: '#fff',
  itemBackground: '#fff',
  defaultHover: '#d6d6d6',
  defaultActive: '#c2c2c2',
};
