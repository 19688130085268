import ConveYourEndpoint from '@/api/ConveYourEndpoint.js';
import { ResponseError } from '@/api/helpers.js';

export default {
  state: {
    rep: null,
    repNumber: '',
    repLoading: false,
  },

  getters: {
    repLoading(state) {
      return state.repLoading;
    },
    rep(state) {
      return state.rep;
    },
    repNumber(state) {
      return state.repNumber;
    },
  },

  actions: {
    async findRep({ commit }, { repNumber, t }) {
      commit('setRepLoading', true);
      try {
        await ConveYourEndpoint.getRep({ repNumber });
        commit('setRepNumber', repNumber);
      } catch (error) {
        throw ResponseError(t, 'findRep', error, {
          notFound: t('store-modules-rep-notFound'),
        });
      } finally {
        commit('setRepLoading', false);
      }
    },

    async loadRep({ commit }, givenProspectId) {
      commit('setRepLoading', true);
      try {
        const rep = await ConveYourEndpoint.getRepProfile({ contactId: givenProspectId });
        commit('setRep', rep);
      } finally {
        commit('setRepLoading', false);
      }
    },
  },

  mutations: {
    setRepLoading: (state, loading) => {
      state.repLoading = loading;
    },
    setRep: (state, rep) => {
      state.rep = rep;
    },
    setRepNumber: (state, repNumber) => {
      state.repNumber = repNumber;
    },
  },
};
