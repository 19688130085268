import { TrackJS } from 'trackjs';

import store from '@/store';

const version     = process.env.VUE_APP_VERSION;
const token       = process.env.VUE_APP_TRACKJS_TOKEN;
const application = process.env.VUE_APP_TRACKJS_APPLICATION;

const network = { error: false };
TrackJS.install({ token, application, version, network });

store.subscribe(({ type, payload }) => {
  const userId = payload.pid;

  if (type !== 'setProfile' || !userId) return;

  TrackJS.configure({ userId });
});
