import { AnalyticsBrowser } from '@segment/analytics-next';
import store from '@/store';

import {
  assertIsString,
  assertIsObject,
} from '@/lib/staticTyping/assert.ts';

export enum SegmentEvent {
  /** The user decides "I want to introduce this recommendation to my rep" */
  threeWayIntroductionInitiated       = 'three_way_introduction_initiated',
  /** The user decides "I like the message now, so let's prepare to send it [via native message app]" */
  threeWayIntroductionMessageDrafted  = 'three_way_introduction_message_drafted',
  selfieAttachedToIntroductionMessage = 'selfie_attached_to_introduction_message',
  /** The user confirms "I have sent the message [via native message app]" */
  // threeWayIntroductionMessageSent    = 'three_way_introduction_message_sent', // TODO: Add when confirmation dialog is acceptable or sms plugin has proper callbacks

  shoppingIntegrationLinkUsed = 'shopping_integration_link_used',
}

interface TrackProperties {
  recommender_id: string,
  recommendation_id: string,
}

interface Prospect {
  id: string;
  first_name: string,
  last_name: string,
  mobile: string,
}

interface Rep {
  rep_number: string;
  office_number: string;
}

export default class Segment {
  private static analyticsInstance: AnalyticsBrowser | undefined;

  private static getTrackProperties(recommendation_id: string): TrackProperties {
    assertIsString(recommendation_id);

    return {
      recommendation_id,
      recommender_id: store.getters.profileId,
    };
  }

  private static getAnalyticsInstance(): AnalyticsBrowser {
    if (!this.analyticsInstance) {
      const segmentAPIKey = process.env.VUE_APP_SEGMENT_KEY;
      if (!segmentAPIKey) {
        throw new Error('Segment API key is not set.');
      }
      this.analyticsInstance = AnalyticsBrowser.load({ writeKey: segmentAPIKey });
    }
    return this.analyticsInstance;
  }

  public static track(givenEvent: SegmentEvent, prospectId: string): void {
    assertIsString(givenEvent);
    assertIsString(prospectId);

    this.getAnalyticsInstance().track(givenEvent, this.getTrackProperties(prospectId));
  }

  public static trackLandingEvent(givenEvent: SegmentEvent, rep: Rep): void {
    assertIsString(givenEvent);
    assertIsObject(rep);

    const { rep_number, office_number } = rep;
    this.getAnalyticsInstance().track(givenEvent, {
      rep_number,
      office_number,
    });
  }

  public static identify(prospect: Prospect, rep: Rep): void {
    assertIsObject(prospect);
    assertIsObject(rep);

    const { rep_number, office_number } = rep;
    const { id, first_name, last_name, mobile } = prospect;
    this.getAnalyticsInstance().identify(id, {
      first_name,
      last_name,
      mobile,
      rep_number,
      office_number,
    });
  }

  public static identifyAnonymousProspect(rep: Rep): void {
    assertIsObject(rep);

    const { rep_number, office_number } = rep;
    this.getAnalyticsInstance().identify({
      rep_number,
      office_number,
    });
  }
}
