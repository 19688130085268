import { loadComponent } from './helpers';
import { Route, RouteConfig, Location } from 'vue-router';

const redirectToRepInfo = ({ params, query }: Route): Location => {
  const {
    pid,
    // Deprecated: calDotComUsername, calDotComEventType
  } = params;
  const {
    delivery,
    rep_portal_id,
    // Deprecated: rep_number, language
  } = query;

  return {
    name: 'rep-info',
    params: (pid === undefined) ? {} : {
      pid,
    },
    query: {
      delivery,
      rep_portal_id,
    },
  };
};

const landingRoutes: RouteConfig[] = [
  {
    path: '/welcome',
    name: 'landing-welcome',
    component: loadComponent('landing/Landing'),
  },
  {
    path: '/info/:pid?',
    name: 'rep-info',
    component: loadComponent('landing/RepInfo'),
    props: true,
  },
  {
    path: '/introduction/:pid',
    name: 'missing-numbers',
    component: loadComponent('landing/MissingNumbers'),
    props: true,
  },
  /**
   * @deprecated since 01/31/2024
   */
  {
    path: '/availabilities/:calDotComUsername/:pid?',
    redirect: redirectToRepInfo,
  },
  /**
   * @deprecated since 01/31/2024
   */
  {
    path: '/availabilities/:calDotComUsername/:calDotComEventType/:pid',
    redirect: redirectToRepInfo,
  },
];

export default landingRoutes;
