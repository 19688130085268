import {
  isError,
  isString,
} from 'lodash';

export function castAsError(givenSubject: unknown): Error {
  if (isError(givenSubject)) return givenSubject;

  const errorMessage = isString(givenSubject) ? givenSubject : JSON.stringify(givenSubject);
  return new Error(errorMessage);
}

export function forceCastAsError(givenSubject: unknown): Error {
  if (isError(givenSubject)) return givenSubject;

  throw new Error('Failed to force cast to Error');
}
