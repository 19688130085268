import {
  isNil,
  isString,
} from 'lodash';

type PhoneNumber = string & { _brand: 'PhoneNumber' };
export default PhoneNumber;

/** The North American Numbering Plan */
class NANP {
  static countryCode = '1';
  static      nationalFormat = '(NPA) NXX-XXXX';
  static internationalFormat = `+${this.countryCode} ${this.nationalFormat}`;
}

export function isPhoneNumber(givenSubject: unknown): givenSubject is PhoneNumber {
  if (isNil(givenSubject) || !isString(givenSubject)) return false;

  const givenSubjectAsDigits = givenSubject.replace(/\D/g, '');
  const      nationalExample = NANP.     nationalFormat.replace(/\W/g, '');
  const internationalExample = NANP.internationalFormat.replace(/\W/g, '');

  function hasValidAreaCode(givenNumber: string): boolean {
    return /^[2-9]/.test(givenNumber);
  }

  switch (givenSubjectAsDigits.length) {
  case      nationalExample.length: return hasValidAreaCode(givenSubjectAsDigits);
  case internationalExample.length: {
    const [firstDigit, ...remainingDigits] = givenSubjectAsDigits;
    return firstDigit === NANP.countryCode && hasValidAreaCode(remainingDigits.join(''));
  }
  default: return false;
  }
}

export function asNullablePhoneNumber(givenSubject: unknown): PhoneNumber | null {
  if (isPhoneNumber(givenSubject)) return givenSubject;

  return null;
}

export function assertIsPhoneNumber(givenSubject: unknown): givenSubject is PhoneNumber {
  if (isPhoneNumber(givenSubject)) return true;

  throw new TypeError('Expected instance of Phone Number');
}

export function assertIsNullablePhoneNumber(givenSubject: unknown): givenSubject is PhoneNumber | null {
  if ((givenSubject === null) || isPhoneNumber(givenSubject)) return true;

  throw new TypeError('Expected instance of Phone Number');
}

export function isEqualTo(leftOperand: PhoneNumber | null = null, rightOperand: PhoneNumber | null = null) {
  assertIsNullablePhoneNumber(leftOperand);
  assertIsNullablePhoneNumber(rightOperand);

  if (isNil(leftOperand) || isNil(rightOperand)) return false;

  return leftOperand === rightOperand || leftOperand.includes(rightOperand) || rightOperand.includes(leftOperand);
}
