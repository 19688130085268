import { Capacitor } from '@capacitor/core';
import { loadComponent } from './helpers';

const appRoutes = [
  {
    path: '/',
    name: 'welcome',
    component: loadComponent('pages/Welcome'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: loadComponent('pages/Feedback'),
  },
  {
    path: '/rep',
    name: 'rep',
    component: loadComponent('pages/Rep'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => {
      const type = Capacitor.isNativePlatform() ? 'Share' : 'ShareWeb';
      return loadComponent(`pages/${type}`)();
    },
  },
];

if (Capacitor.isNativePlatform()) {
  appRoutes.push({
    path: '/introduction/:id',
    name: 'introduction',
    component: loadComponent('pages/Introduction'),
    props: true,
  });
}

export default appRoutes;
