enum DeviceOS {
  /** Apple's mobile operating system */
  iOS,
  /** Google's mobile operating system */
  Android,

  /** Apple's desktop operating system */
  macOS,
  /** Microsoft's desktop operating system */
  Windows,
}

export default DeviceOS;
