import Vue from 'vue';
import Vuex from 'vuex';

import contacts from './modules/contacts';
import rep from './modules/rep';
import profile from './modules/profile';
import introduction from './modules/introduction';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    contacts,
    rep,
    profile,
    introduction,
  },

  strict: true,
});

export default store;
