import { en } from 'vuetify/lib/locale';

export default {
  ...en,

  'generic': {
    response: {
      networkError: `Your network isn't letting this device connect to our server. Try a different network!`,
      unexpectedError: 'Unexpected error, have your rep contact support.',
    },
  },

  'invalid': 'invalid',
  'welcome-header': 'Welcome',
  'embedded-video-id': 'o5skwik3xx',
  'rep-number-field': 'Enter Rep Number',
  'find-rep-button': 'Find Rep',
  'invalid-rep-number': 'Invalid rep number',
  'store-modules-rep-notFound': `Didn't find this rep, please try a different rep number!`,
  'meeting-code-prompt': 'Next, please enter your four-digit meeting code for confirmation.',
  'meeting-code-field': 'Meeting Code',
  'meeting-button-label': 'Confirm Meeting',
  'meeting-confirmation-success': 'Found meeting for',
  'meeting-confirmation-retry': 'Enter New Code',
  'continue': 'Continue',
  'invalid-meeting-number': 'Invalid meeting code',
  'meeting-does-not-exist': `Didn't find this meeting, please try a different meeting code!`,
  'survey': {
    'title': 'Feedback',
    'button': {
      true: 'Submit Feedback',
      false: 'Continue',
    },
    'field-label': 'Comment',
  },
  'change-rep-option': 'Change Rep',
  'call-button-label': 'Call',
  'text-button-label': 'Text',
  'share-button-label': 'Share',
  'sharing-button-label': 'Sharing',
  'shared-status': 'shared',
  'share-manual': 'Recommend some nice people to your rep!',
  'share-first-name-field-label': 'First Name',
  'share-last-name-field-label': 'Last Name',
  'share-mobile-number': 'Mobile Number',
  'share-previously-recommended': 'You have already recommended',
  'share-error-pre-name': 'We were unable to save your update for',
  'share-error-post-name': 'Please check that their number is valid and that you have a good internet connection.',
  'grant-access-prompt': `To let you share this experience with other people, the app will need to access your phone's contact list.`,
  'grant-access-header': `Let's find nice people to recommend to your rep!`,
  'grant-access-paragraph-1': `To let you share this experience with other people, the app will need to access your phone's contact list.`,
  'grant-access-paragraph-2': 'Granting access to your contacts will not immediately share any information about your friends with your sales rep.',
  'grant-access-paragraph-3': 'Only after you select and confirm your chosen friends will any information on those contacts be shared with your rep.',
  'share-dialog-title': 'Confirm Sharing Info',
  'share-dialog-header': 'Share the contact information for',
  'faq-1-header': 'What information is shared?',
  'faq-1-answer': `Only a contact's first name, last name, and phone number are shared.`,
  'faq-2-header': 'How is that information shared?',
  'faq-2-answer': `It's uploaded to a private, secure system that allows your rep to access it.`,
  'faq-3-header': 'How is that information used?',
  'faq-3-answer': 'Only your rep can access it. Cutco will not directly access nor ever sell this information. It will be purged when your rep no longer needs it.',
  'grant-access-button-label': 'Grant Access',
  'grant-access-error': 'Unable to access contacts. Please grant access below to share.',
  'grant-access-reprompt': `Without granting access, you won't be able to help your rep!`,
  'privacy-policy': 'Privacy Policy',
  'contact-list-prompt': 'Select nice people to recommend',
  'search-field-placeholder': 'Search',
  'no-search-results': 'No more search results',
  'contact': 'Contact',
  'contacts': 'Contacts',
  'with': 'with',
  'share-filter-label': 'View Shared',
  'remove-filter-label': 'View All',
  'introduce-button-label': 'Introduce',
  'intro-done': 'Introduced',
  'introduction-title': 'Introduction',
  'change-intro-button-label': 'Change',
  'select-language': 'Select Language',
  'text-message-prompt': 'Review and edit message',
  'take-selfie-label': 'Take a Selfie',
  'remove-photo-button-label': 'Delete Photo',
  'copy-to-sms-button-label': 'Create Message',
  'create-message-disclaimer': `You'll have another chance to review and edit the message before sending.`,
  'landing-fix-name': 'Fix Name',
  'landing-phoneNumber': 'Phone Number',
  'landing-cancel': 'Cancel',
  'landing-save': 'Save',
  'landing-next': 'Next',
  'landing-skip': 'Skip',
  'required-field': 'Field is required',
  'invalid-max-length': 'The maximum length of this field is {{}} characters',
  'invalid-phone-number': 'Invalid phone number',
  'landing-empty': 'I already got the numbers I needed, but thanks anyway!',
  'landing-success': `That's all, thank you so much!`,
  'rep-number': 'Rep Number',
  'rep-bio': 'Bio',
  'not-found-title': 'Not Found',
  'not-found-body': 'We were unable to find the right records for you. Please reach out to your rep.',
  'landing-introduction-greeting': 'Hi',
  'landing-introduction-body': `Thank you for helping me with this. On the following page is some people that I don't have numbers for. If you could provide numbers for any of them, it would be really helpful. Thanks again!`,
  'landing-introduction-proceed': 'View the List',
  'landing-description': 'Help your rep after your demo by giving them feedback using the MyCutcoRep mobile app.',
  'landing-download-button': 'Download the App',
  'landing-content-description': 'An opportunity to help an aspiring entrepreneur on their journey to success.',
  'landing-content-call-to-action': 'Download this app to provide your rep with valuable feedback.',
  'landing-hide': 'Hide',
  'landing-read-more': 'Read more',
  'landing-rep-greeting': 'Hey',
  'landing-schedule-appointment': 'Schedule Appointment',
  'landing-appointment-type-title': 'How would you like to meet with {{}}?',
  'landing-appointment-type-in-person': 'In-person',
  'landing-appointment-private-virtual': 'Virtually',
  'landing-appointment-private-virtual-request': 'Request Virtual',
  'landing-appointment-private-virtual-sms': `Hey, {{}}, this is userName! I'd like to book a virtual Cutco appointment with you as soon as you have available. I look forward to hearing from you!`,
  'landing-appointment-private-in-person': 'At my home',
  'landing-appointment-private-in-person-request': 'Request in-home',
  'landing-appointment-private-in-person-sms': `Hey, {{}}, this is userName! I'd like to book an in-person Cutco appointment with you as soon as you have available. I look forward to hearing from you!`,
  'landing-appointment-event-in-person': 'At an event',
  'landing-appointment-event-in-person-sms': `Hey, {{}}, this is userName! I'd like to know when you're having your next event. I look forward to hearing from you!`,
  'landing-shop-now': 'Shop Now',
  'landing-privacy': 'privacy',
  'landing-terms': 'terms',
  'landing-privacy-policy': '<h2>Privacy Policy</h2>' +
    'Vector Marketing Corporation ("we") respects and is committed to protecting your privacy. That is why we have adopted this Privacy Policy applicable to and covering:www.mycutcorep.app("this website").<br/><br/>' +
    'This Privacy Policy lets you know how your personal information is processed and used. We promise to only use your personal information in ways that are compatible with this Privacy Policy.' +
    '<h3>Collection & Use of Personal Information</h3>' +
    'We do not automatically log personal data nor do we link information automatically logged by other means with personal data about specific individuals. We do not collect information about our visitors from other sources, such as public records or bodies, or private organizations.<br/><br/>' +
    `If you choose to submit information on our app/website, that information will be kept in the strictest confidence and it will never be shared with anyone outside of our organization and organization's providers. No information you provide will ever be used for any type of marketing purpose by anyone else.` +
    '<h3>Cookies</h3>' +
    'Some of our web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file that may be used to collect information about web site activity. We may track non-personally identifying information such as pages visited, click stream patterns, and dates and times that our site is accessed.<br/><br/>' +
    'This information is collected in an aggregate manner and we do not collect individual browsing profiles for individual visitors.<br/><br/>' +
    'Our use of cookies and other tracking technologies allows us to improve our web site. We do not use cookies to store personal data nor do we link non-personal information stored in cookies with personal data about specific individuals.<br/><br/>' +
    'We do not share this aggregate, non-personally identifying information with anyone outside of our organization and organization providers.' +
    '<h3>Legal Disclaimer</h3>' +
    'Though we will make every effort to preserve user privacy, we may need to disclose personal information when required by law wherein we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order, or legal process served upon us.<br/><br/>' +
    'If we decide to change our privacy policy, we will post those changes to this privacy statement and other places we deem appropriate so you are always aware of what information we collect, how we use it, and under what circumstances, if any, we would disclose it.<br/><br/>' +
    'We will use your information only in accordance with the privacy policy under which the information was collected.',
};
