<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <main
        class="content"
        :class="{full: isLanding}"
      >
        <router-view />
      </main>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

import App from '@/api/App.ts';

export default {
  name: 'Layout',
  computed: {
    isLanding() {
      return this.$route.name === 'landing';
    },
  },
  methods: {
    ...mapActions(['tryToSignInProspect']),
    async autoLogin() {
      if (!App.isRunningNatively && !navigator.cookieEnabled) return;

      const repNumber = localStorage.getItem('repNumber');
      const meetingCode = localStorage.getItem('pinNumber');

      if (!repNumber || !meetingCode) return;

      try {
        await this.tryToSignInProspect({ havingAppointmentWith: meetingCode });
        const prospectWasAlreadySurveyed = localStorage.getItem('surveyed');
        const initialRoute = prospectWasAlreadySurveyed ? '/rep' : '/feedback';
        this.$router.push(initialRoute);
      } catch (error) {
        if (error.message === 'Network Error') return;

        console.error('autoLogin', error);
      }
    },
  },
  created() {
    this.autoLogin();
  },
};
</script>

<style lang="less" src="@/assets/styles/vuetify/vuetify-custom.less"></style>
<style lang="less" src="@/assets/styles/main.less"></style>
