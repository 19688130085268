import Vuetify from 'vuetify/lib';
import en from './languages/en';
import es from './languages/es';
import fr from './languages/fr';
import theme from './theme';

const preset = {
  lang: {
    locales: { en, es, fr },
    current: navigator.language.split('-').shift(),
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    light: true,
    themes: {
      light: theme,
    },
    options: {
      customProperties: true,
    },
  },
};

const vuetify = new Vuetify(preset);

export default vuetify;
