<script setup lang="ts">
import {
  onMounted,
} from 'vue';

import * as LiveUpdate  from '@capacitor/live-updates';
import { SplashScreen } from '@capacitor/splash-screen';

import App from '@/api/App.ts';

import {
  castAsError,
} from '@/lib/utilitiesByType/error.ts';

onMounted(async () => {
  try {
    if ((process.env.NODE_ENV === 'development') || !App.isRunningNatively) return;

    const result = await LiveUpdate.sync();

    if (!result.activeApplicationPathChanged) return;

    await LiveUpdate.reload();
  } catch (errorToBeCasted) {
    const error = castAsError(errorToBeCasted);

    if (error.message.includes('Failure')) return;

    throw error;
  } finally {
    await SplashScreen.hide();
  }
});
</script>

<template>
  <div :class="{ 'web-version': !App.isRunningNatively }">
    <router-view />
  </div>
</template>
