export default {
  setContacts: (state, contacts) => {
    contacts.sort(sortContacts);
    state.contacts = contacts;
  },

  setContactsLocally: (state, contacts) => {
    contacts.sort(sortContacts);
    state.contacts = contacts;
    localStorage.setItem('contacts', JSON.stringify(contacts));
  },

  updateContactInList: (state, contact) => {
    const contactIndex = state.contacts.findIndex(({ id }) => id === contact.id);
    if (contactIndex !== -1) {
      state.contacts.splice(contactIndex, 1, contact);
    }
    saveChangedContacts(state.contacts);
  },

  setContactsLoading: (state, loading) => {
    state.loadingContacts = loading;
  },
};

function sortContacts(a, b) {
  return (a.first_name || a.last_name).localeCompare(b.first_name || b.last_name);
}

function saveChangedContacts(arr) {
  const filteredArr = arr.filter(({ introduced, serverId, saved }) => introduced || serverId || saved);
  localStorage.setItem('contacts', JSON.stringify(filteredArr));
}
