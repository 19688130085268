export default {
  contacts(state) {
    return state.contacts;
  },
  loadingContacts(state) {
    return state.loadingContacts;
  },
  singleContact(state) {
    return (contactId) => {
      return state.contacts.find(({ id, serverId }) => id == contactId || serverId == contactId);
    };
  },
};
