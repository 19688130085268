import { Capacitor } from '@capacitor/core';

import OperatingSystem from '@/lib/externalModels/OperatingSystem.ts';

/** Offers utilities the describe the nature of this app */
export default class App {
  static get isRunningNatively(): boolean {
    return Capacitor.isNativePlatform();
  }

  static isRunningNativelyOn(givenOS: OperatingSystem): boolean {
    // Checked explicitly because it's possible to run this app on a mobile OS via a device's web browser
    switch (Capacitor.getPlatform()) {
    case     'ios': return givenOS === OperatingSystem.iOS;
    case 'android': return givenOS === OperatingSystem.Android;
    default       : return false;
    }
  }
}
