export const ResponseError = (t, methodName, error, errorMessages) => {
  const message = chooseResponseErrorMessage(t, methodName, error, errorMessages);
  return new Error(message);
};

export const chooseResponseErrorMessage = (t, methodName, error, errorMessages = {}) => {
  const status = error.response?.status;
  const { badRequest, unauthorized, forbidden, notFound, fallback } = errorMessages;

  if (status === 400 && !!badRequest  ) return badRequest;   // eslint-disable-line space-in-parens
  if (status === 401 && !!unauthorized) return unauthorized; // eslint-disable-line space-in-parens
  if (status === 403 && !!forbidden   ) return forbidden;    // eslint-disable-line space-in-parens
  if (status === 404 && !!notFound    ) return notFound;     // eslint-disable-line space-in-parens

  if (error.message === 'Network Error') {
    return t('generic.response.networkError');
  }

  console.error(methodName, error);
  return fallback || t('generic.response.unexpectedError');
};
